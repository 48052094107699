import React, { useRef, useEffect } from 'react';
import { parseToRgba, readableColor, readableColorIsBlack, readableColorIsBlack as isLite } from 'color2k';

const isDark = (color) => !isLite(color);
const rgbify = (color) => parseToRgba(color).slice(0, 3).toString();

import { schemes } from '../../../tabula.config';
import * as sys from 'src/system';
import { Box, Chain, Stack, Grid } from 'src/system2';

const currentScheme = 'lite';
const defaultScale = 'base';
const defaultUiScale = 'action';

const isObject = () => false;

function useSchemeProps({ className, style, sx }) {
  const classNameRef = useRef(className);
  const styleRef = useRef(style);

  const schemePropsRef = useRef(reduceSchemeProps({ className, style, sx }));
  /*
    TODO: make a ref for className, style, sx separately.
  */
  useEffect(() => {
    const schemePropsHaveChanged = false; // TODO: make a function here
    if (schemePropsHaveChanged) {
      schemePropsRef.current = reduceSchemeProps({ className, style, sx });
    }
  }, [className, style, sx]);
  return schemePropsRef.current;
}

function Box2({ as: El = 'div', className, style, sx, ...rest }) {
  ({ className, style } = useSchemeProps({ className, style, sx }));
  return <El {...{ className, style, ...rest }} />;
}

function reduceSchemeProps({ scale, bgScale = scale, tone, fgTone = tone, ...rest }) {
  const schemeProps = {
      bgScale,
      fgTone,
      ...rest,
    },
    style = {},
    classList = [];
  for (const key in schemeProps) {
    const value = schemeProps[key];
    if (value === undefined) continue;
    const varName = key
      .split(/(?<=\w)([A-Z][a-z])/g)
      .map((s, i) => (i % 2 == 0 ? s : `-${s.toLowerCase()}`))
      .join('');
    if (!isObject(value)) {
      /**
       * NON-RESPONSIVE values
       */
      classList.push(varName);
      const isScheme = /scheme$/.test(varName);
      const isScale = /-scale$/.test(varName);
      const isTone = /-tone$/.test(varName);
      if (isScheme) {
        /**
         * SCHEME values
         * - assigns
         */
        Object.assign(
          style,
          schemes[currentScheme][defaultScale].reduce(
            (obj, color, i) => {
              obj[`--bg-tone-${i + 1}`] = color;
              obj[`--ui-tone-${i + 1}`] = schemes[currentScheme][defaultUiScale][i];
              return obj;
            },
            {
              // TODO: calculate these with `color2k`
              '--is-scheme-dark': 'initial',
              '--is-bg-dark': 'initial',
              '--bg-hue': 'SOME_HUE',
              '--is-ui-dark': 'initial',
              '--ui-hue': 'SOME_HUE',
            },
          ),
        );
      } else if (isScale) {
        /**
         * SCALE values
         */
        const scaleValue = schemes[currentScheme][value];
        const scaleProp = varName.slice(0, 2);
        Object.assign(
          style,
          scaleValue.reduce(
            (obj, color, i) => {
              obj[`--${scaleProp}-tone-${i + 1}`] = color;
              return obj;
            },
            {
              // TODO: calculate these with `color2k`
              [`--is-${scaleProp}-dark`]: 'initial',
              [`--${scaleProp}-hue`]: 'SOME_HUE',
            },
          ),
        );
      } else if (isTone) {
        /**
         * TONE value
         */
        style[`--${varName}`] = `var(--${varName}-${value})`;
      } else {
        /**
         * (OTHER) values
         */
        style[`--${varName}`] = `var(--${varName}-${value})`;
      }
    } else {
      /**
       * RESPONSIVE values
       */
    }
  }
  return {
    className: classList.filter(Boolean).join(' ').trim() || undefined,
    style: style && Object.keys(style).length > 0 ? style : undefined,
  };
}

export default function Page() {
  return (
    <div className="page">
      <div css={[sys.wrapLast, sys.frame]}>
        <h1>Scheme Testing</h1>
      </div>
    </div>
  );
}
